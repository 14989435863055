<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-edit fa-mdr"></i> <span class="h5"> Estadísticas del Servicio No Conforme </span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA SERVICIO NO CONFORME:
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="ico fas fa-frown-open fa-3x pb-1"></i>
                            <br>
                            <span class="lg-numero">{{listaServicios.length}}</span>
                            <br>
                            <span class="text-muted">Servicio no Conforme</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestion de Servicio no Conforme</span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" :to="{name:'Proyectos'}">
                                <i style="inline-size: auto" class="fas fa-arrow-left fa-3x pb-1"></i><br><span>Volver</span>
                            </b-button>
                        </b-col>
                        <b-col cols="6" class="my-2">
                            <b-button block variant="custom" @click="modalRegistrarServicioNoConforme = true">
                                <i style="inline-size: auto" class="fas fa-plus-circle fa-3x pb-1"></i><br><span>Nuevo Registro de Servicio no conforme</span>
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="12">
            <CCard>
                <CCardHeader color="primario" text-color="white">
                    <i class="fas fa-list fa-md"></i><span class="h5">Servicio no conforme registrados </span>
                </CCardHeader>
                <CCardBody>
                    <b-row>
                        <b-col md="3">
                            <b-form-group label="Registros por página" class="text-muted">
                                <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col md="6">
                        </b-col>
                        <b-col md="3">
                            <b-form-group label="Busqueda:" class="text-muted">
                                <b-input-group size="xl">
                                    <b-input-group-prepend is-text>
                                        <i class="fas fa-search my-0 mx-0"></i>
                                    </b-input-group-prepend>
                                    <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                </b-input-group>
                            </b-form-group>
                        </b-col>
                        <b-col md="12">
                            <b-table bordered hover show-empty mediun responsive outlined :items="listaServicios" :fields="camposQuejas" :current-page="paginaActual" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                <template v-slot:cell(opciones)="param">
                                    <b-button size="sm" v-c-tooltip.hover.click="'Ver detalles'" @click="abrirmodalActualizarServicioNoConforme(param)" variant="dark" class=" mr-1 mb-1">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </span>
                                    </b-button>

                                    <b-button size="sm" class="mr-1 mb-1" v-c-tooltip.hover.click="'Eliminar'" @click="eliminarServicio(param)" variant="danger">
                                        <span class="btn-inner--icon">
                                            <i class="fas fa-trash-alt  my-0 mx-0"></i>
                                        </span>
                                    </b-button>
                                </template>
                                <template v-slot:cell(idProyecto)="param">
                                    {{comboProyecto.find(x => x.idProyecto==param.item.idProyecto).nombre}}
                                </template>
                                <template v-slot:cell(referencias)="data">{{ data.item.referencias | truncate(80)}}</template>

                            </b-table>
                        </b-col>
                    </b-row>
                </CCardBody>
                <CCardFooter>
                    <b-pagination size="sm" v-model="paginaActual" :total-rows="filasTotales" :per-page="porPagina" first-number last-number align="right" class="my-0"></b-pagination>
                </CCardfooter>
            </CCard>
        </b-col>
    </b-row>
    <CModal :show.sync="modalRegistrarServicioNoConforme" size="xl" color="primario">
        <template #header>
            <h6 class="modal-title">
                <i class="fas fa-plus-square fa-md"></i>
                <span class="h5">Registro de Servicio no conforme</span>
            </h6>
            <CButtonClose @click="modalRegistrarServicioNoConforme = false" class="text-white" />
        </template>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(registrarControlEntrega)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                            <b-form-group label="Proyecto:" class="mb-2">
                                <v-select @input="obtenerDiasProyecto" :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosServicio.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosServicio.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="días de itinerario" rules="required" v-slot="{errors}">
                            <b-form-group label="Días de itinerario:" class="mb-2">
                                <v-select :disabled="datosServicio.idProyecto == ''" :reduce="listaDiasItinerario =>listaDiasItinerario.id" label="dia" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosServicio.idDia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosServicio.idDia" :options="listaDiasItinerario">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Itinerarios'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha registro" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de registro:" class="mb-2">
                                <b-form-input v-model="datosServicio.fechaDeRegistro" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="servicio no conforme" v-slot="validationContext">
                            <b-form-group label="Servicio no conforme:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la incoformidad" v-model="datosServicio.servicioNoConforme"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="causas del servicio no conforme" v-slot="validationContext">
                            <b-form-group label="Causas del servicio no conforme:" class="mb-2">
                                <b-form-textarea v-model="datosServicio.causasDelServicioNoConforme" rows="3" max-rows="6" placeholder="Ingrese argumento" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="acciones a tomar" v-slot="validationContext">
                            <b-form-group label="Acciones a tomar:" class="mb-2">
                                <b-form-textarea v-model="datosServicio.accionesATomar" rows="3" max-rows="6" placeholder="Ingrese argumento" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="responsable" v-slot="validationContext">
                            <b-form-group label="Responsable:" class="mb-2">
                                <b-form-textarea v-model="datosServicio.responsable" rows="3" max-rows="6" placeholder="Ingrese lo indicado" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-textarea v-model="datosServicio.fecha" rows="3" max-rows="6" placeholder="Ingrese la fecha" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalRegistrarServicioNoConforme = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

    <CModal :show.sync="modalActualizarServicioNoConforme" size="lg" color="primario">
        <template #header>
            <h6 class="modal-title"> <i class="fas fa-edit fa-md"></i>
                <span class="h5"> Actualizar lote de productos</span></h6>
            <CButtonClose @click="modalActualizarServicioNoConforme = false" class="text-white" />
        </template>
        <validation-observer ref="observer2" v-slot="{ handleSubmit }">
            <b-form @submit.stop.prevent="handleSubmit(actualizarQuejas)">
                <b-row>
                    <b-col md="6">
                        <validation-provider name="proyecto" rules="required" v-slot="{errors}">
                            <b-form-group label="Proyecto:" class="mb-2">
                                <v-select @input="obtenerDiasProyecto" :reduce="comboProyecto =>comboProyecto.idProyecto" label="nombre" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarServicio.idProyecto , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarServicio.idProyecto" :options="comboProyecto" @search:blur="blurProyecto">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Proyectos'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="días de itinerario" rules="required" v-slot="{errors}">
                            <b-form-group label="Días de itinerario:" class="mb-2">
                                <v-select :disabled="datosActualizarServicio.idProyecto == ''" :reduce="listaDiasItinerario =>listaDiasItinerario.id" label="dia" placeholder="Seleccione una opción" :class=" {'style-valid-select': datosActualizarServicio.idDia , 'style-invalid-select is-invalid': !!errors.length}" v-model="datosActualizarServicio.idDia" :options="listaDiasItinerario">
                                    <span slot="no-options" class="text-muted">No hay datos que mostrar. <router-link :to="{name:'Itinerarios'}"> Registra aquí</router-link></span>
                                </v-select>
                                <div class="invalid-feedback">{{ errors[0]}}</div>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha registro" :rules="{required: true}" v-slot="validationContext">
                            <b-form-group label="Fecha de registro:" class="mb-2">
                                <b-form-input v-model="datosActualizarServicio.fechaDeRegistro" type="date" :state="getValidationState(validationContext)"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col md="6">
                        <validation-provider name="servicio no conforme" v-slot="validationContext">
                            <b-form-group label="Servicio no conforme:" class="mb-2">
                                <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la incoformidad" v-model="datosActualizarServicio.servicioNoConforme"></b-form-input>
                                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="causas del servicio no conforme" v-slot="validationContext">
                            <b-form-group label="Causas del servicio no conforme:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarServicio.causasDelServicioNoConforme" rows="3" max-rows="6" placeholder="Ingrese argumento" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="12">
                        <validation-provider name="acciones a tomar" v-slot="validationContext">
                            <b-form-group label="Acciones a tomar:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarServicio.accionesATomar" rows="3" max-rows="6" placeholder="Ingrese argumento" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="responsable" v-slot="validationContext">
                            <b-form-group label="Responsable:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarServicio.responsable" rows="3" max-rows="6" placeholder="Ingrese lo indicado" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>
                    <b-col md="6">
                        <validation-provider name="fecha" v-slot="validationContext">
                            <b-form-group label="Fecha:" class="mb-2">
                                <b-form-textarea v-model="datosActualizarServicio.fecha" rows="3" max-rows="6" placeholder="Ingrese la fecha" :state="getValidationState(validationContext)"></b-form-textarea>
                            </b-form-group>
                        </validation-provider>
                    </b-col>

                    <b-col lg="12" class="text-right my-2">
                        <b-button :disabled="disabled" variant="success" class=" mr-2" type="submit">
                            Guardar
                        </b-button>
                        <b-button variant="danger" @click="modalActualizarServicioNoConforme = false">
                            Cancelar
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
        <template #footer>
            <span></span>
        </template>
    </CModal>

</div>
</template>

<script>
import firebase from 'firebase';
const dbServicioNoConforme = firebase.firestore().collection('servicioNoConforme');
const dbProyecto = firebase.firestore().collection('proyectos');
const dbItinerario = firebase.firestore().collection('itinerarios');

export default {
    data() {
        return {
            disabled: false,
            listaDiasItinerario: [],
            arrayDiasItinerario: [],
            listaServicios: [],
            camposQuejas: [{
                    key: "fechaDeRegistro",
                    label: "Fecha",
                    class: "text-center",
                },
                {
                    key: "servicioNoConforme",
                    label: "Servicio no conforme",
                    class: "text-center",
                },
                {
                    key: "causasDelServicioNoConforme",
                    label: "Causas del Servicio no conforme",
                    class: "text-center"
                },
                {
                    key: "responsable",
                    label: "Responsable",
                    class: "text-center",
                },
                {
                    key: "opciones",
                    label: "",
                    class: "text-center",
                }

            ],

            comboProyecto: [],

            filasTotales: 1,
            paginaActual: 1,
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            filter: null,
            filterOn: [],

            datosServicio: {
                idCliente: '',
                idDia: null,
                idProyecto: '',
                nombre: '',
                fechaDeRegistro: moment().format('YYYY-MM-DD'),
                servicioNoConforme: '',
                causasDelServicioNoConforme: '',
                accionesATomar: '',
                responsable: '',
                fecha: '',
            },
            datosActualizarServicio: {
                idServicioNoConforme: '',
                idProyecto: '',
                idDia: null,
                idCliente: '',
                fechaDeRegistro: moment().format('YYYY-MM-DD'),
                servicioNoConforme: '',
                causasDelServicioNoConforme: '',
                accionesATomar: '',
                responsable: '',
                fecha: '',
            },

            modalRegistrarServicioNoConforme: false,
            modalActualizarServicioNoConforme: false,

        }
    },
    methods: {
        onFiltered(itemsFiltrados) {
            this.filasTotales = itemsFiltrados.length
            this.paginaActual = 1
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

        blurProyecto() {
            this.computedForm.refs.proyecto.validate();
        },
        blurProyectoActualizar() {
            this.computedActualizarForm.refs.proyecto.validate();
        },
        obtenerDiasProyecto(e) {
            this.datosServicio.idDia = null;
            this.datosActualizarServicio.idDia = null;
            this.listaDiasItinerario = this.arrayDiasItinerario.filter(x => x.idProyecto == e);
        },
        listarItinerario() {
            let me = this;
            dbItinerario
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosServicio.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.arrayDiasItinerario = [];
                    let itinerarios = [];
                    querySnapshot.forEach((doc) => {
                        itinerarios.push({
                            idItinerario: doc.id,
                            idProyecto: doc.data().idProyecto,
                            listaDias: doc.data().listaDias,
                        });
                    });
                    itinerarios.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                    for (const i in itinerarios) {
                        for (const j in itinerarios[i].listaDias) {
                            me.arrayDiasItinerario.push({
                                idItinerario: itinerarios[i].idItinerario,
                                idProyecto: itinerarios[i].idProyecto,
                                dia: itinerarios[i].listaDias[j].nombre,
                                id: j
                            });
                        }
                    }
                });
        },
        /* agregarActividadActualizar(k) {
            let me = this;
            me.datosActualizarItinerario.listaDias[k].listaActividades.push({
                horaInicio: '',
                horaFin: '',
                actividad: ''
            })
        },
        quitarActividadActualizar(param, k) {
            let me = this;
            me.datosActualizarItinerario.listaDias[k].listaActividades.splice(param.index, 1);
        }, */
        listarProyectos() {
            let me = this;
            dbProyecto
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosServicio.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.comboProyecto = [];
                    querySnapshot.forEach((doc) => {
                        me.comboProyecto.push({
                            idProyecto: doc.id,
                            nombre: doc.data().nombre,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.comboProyecto.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));
                    me.listarServicios();
                });
        },
        listarServicios() {
            let me = this;
            dbServicioNoConforme
                .where("estado", "==", 2)
                .where('idCliente', '==', String(me.datosServicio.idCliente))
                .onSnapshot((querySnapshot) => {
                    me.listaServicios = [];
                    querySnapshot.forEach((doc) => {
                        me.listaServicios.push({
                            idServicioNoConforme: doc.id,
                            idDia: doc.data().idDia,
                            idProyecto: doc.data().idProyecto,
                            fechaDeRegistro: moment(doc.data().fechaDeRegistro).format('YYYY-MM-DD'),
                            servicioNoConforme: doc.data().servicioNoConforme,
                            causasDelServicioNoConforme: doc.data().causasDelServicioNoConforme,
                            responsable: doc.data().responsable,
                            accionesATomar: doc.data().accionesATomar,
                            fecha: doc.data().fecha,
                            estado: doc.data().estado,
                            fechaCreacion: doc.data().fechaCreacion,
                            fechaModificacion: doc.data().fechaModificacion,
                        });
                    });
                    me.listaServicios.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0));

                });
        },

        registrarControlEntrega() {
            let me = this;
            me.disabled = true;
            dbServicioNoConforme.add({
                    idCliente: me.datosServicio.idCliente,
                    idProyecto: me.datosServicio.idProyecto,
                    idDia: me.datosServicio.idDia,
                    fechaDeRegistro: me.datosServicio.fechaDeRegistro,
                    servicioNoConforme: me.datosServicio.servicioNoConforme,
                    causasDelServicioNoConforme: me.datosServicio.causasDelServicioNoConforme,
                    accionesATomar: me.datosServicio.accionesATomar,
                    responsable: me.datosServicio.responsable,
                    fecha: me.datosServicio.fecha,
                    estado: 2,
                    fechaCreacion: firebase.firestore.Timestamp.fromDate(new Date()),
                    fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                })
                .then((docRef) => {
                    //console.log("Creado correctamente ID: ", docRef.id);
                    me.swat('success', 'Registrado satisfactoriamente')
                    me.modalRegistrarServicioNoConforme = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        actualizarQuejas() {
            let me = this;
            me.disabled = true;
            let obj = {
                idCliente: me.datosActualizarServicio.idCliente,
                idProyecto: me.datosActualizarServicio.idProyecto,
                idDia: me.datosActualizarServicio.idDia,
                fechaDeRegistro: me.datosActualizarServicio.fechaDeRegistro,
                servicioNoConforme: me.datosActualizarServicio.servicioNoConforme,
                causasDelServicioNoConforme: me.datosActualizarServicio.causasDelServicioNoConforme,
                accionesATomar: me.datosActualizarServicio.accionesATomar,
                responsable: me.datosActualizarServicio.responsable,
                fecha: me.datosActualizarServicio.fecha,
                estado: 2,
                fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
            }

            dbServicioNoConforme.doc(me.datosActualizarServicio.idServicioNoConforme).update(obj)
                .then(() => {
                    me.swat('success', 'Modificado satisfactoriamente')
                    me.modalActualizarServicioNoConforme = false
                    me.disabled = false;
                })
                .catch((error) => {
                    me.disabled = false;
                    me.swat('error', 'Algo salió mal!')
                });

        },
        eliminarServicio(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar el guía?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbServicioNoConforme.doc(param.item.idServicioNoConforme).update({
                            estado: 1,
                            fechaModificacion: firebase.firestore.Timestamp.fromDate(new Date()),
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                            me.disabled = false;
                        })
                        .catch((error) => {
                            me.disabled = false;
                            me.swat('error', 'Algo salió mal!')
                        });

                }
            })
        },
        abrirmodalActualizarServicioNoConforme(param) {

            this.datosActualizarServicio.idProyecto = param.item.idProyecto;
            this.obtenerDiasProyecto(this.datosActualizarServicio.idProyecto);
            this.datosActualizarServicio.idDia = param.item.idDia;
            this.datosActualizarServicio.idServicioNoConforme = param.item.idServicioNoConforme;
            this.datosActualizarServicio.fechaDeRegistro = param.item.fechaDeRegistro;
            this.datosActualizarServicio.servicioNoConforme = param.item.servicioNoConforme;
            this.datosActualizarServicio.causasDelServicioNoConforme = param.item.causasDelServicioNoConforme;
            this.datosActualizarServicio.accionesATomar = param.item.accionesATomar;
            this.datosActualizarServicio.responsable = param.item.responsable;
            this.datosActualizarServicio.fecha = param.item.fecha;
            this.modalActualizarServicioNoConforme = true
        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
        resetmodalRegistrarServicioNoConforme() {
            this.$nextTick(() => {
                this.$refs.observer.reset();
            });
            this.datosServicio = {
                idCliente: '',
                idDia: null,
                idProyecto: '',
                nombre: '',
                fechaDeRegistro: moment().format('YYYY-MM-DD'),
                servicioNoConforme: '',
                causasDelServicioNoConforme: '',
                accionesATomar: '',
                responsable: '',
                fecha: '',
            };
            // this.datosServicio.fechaDeRegistro = moment().format('YYYY-MM-DD');
            // this.datosServicio.servicioNoConforme = '';
            // this.datosServicio.causasDelServicioNoConforme = '';
            // this.datosServicio.accionesATomar = '';
            // this.datosServicio.responsable = '';
            // this.datosServicio.fecha = '';
        },
        resetmodalActualizarServicioNoConforme() {
            this.$nextTick(() => {
                this.$refs.observer2.reset();
            });
        },
    },
    computed: {
        computedForm() {
            return this.$refs.observer;
        },
        computedFormActualizar() {
            return this.$refs.observer2;
        },

    },
    watch: {
        modalRegistrarServicioNoConforme: function (val) {
            if (val == false) {
                this.resetmodalRegistrarServicioNoConforme();
            }
        },
        modalActualizarServicioNoConforme: function (val) {
            if (val == false) {
                this.resetmodalActualizarServicioNoConforme();
            }
        }
    },
    mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.datosServicio.idCliente = this.datosActualizarServicio.idCliente = user.idCliente;
            this.listarProyectos();
            this.listarItinerario();
            this.listarServicios();
        }
    }

}
</script>
